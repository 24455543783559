.Score {
    color: #e3ff92;
    display: grid;
    align-items: center;
    padding: 1rem;
}

.NavButtons {
    display: flex;
    justify-content: space-between;
    padding: 1rem 0;
}

.navButton {
    border: 1px solid #e3ff92;
    padding: 1rem;
    cursor: pointer;
    &:hover {
        color: #e3ff9280;
    }
}