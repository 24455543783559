.Pause {
    color: #e3ff92;
    background-color: #00000080;
    display: grid;
    align-content: center;
    justify-items: center;
    position: absolute;
    z-index: 3;
}

.pausedTitle {
    font-size: 2rem;
    margin: 2rem;
}

.continueButton {
    border: 1px solid #e3ff92;
    padding: 1rem;
    cursor: pointer;
    &:hover {
        color: #e3ff9280;
    }
}