

.Board {
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-content: center;
          align-content: center;
  display: grid; }

.GameOver {
  color: #e3ff92;
  background-color: #00000080;
  display: grid;
  -webkit-align-content: center;
          align-content: center;
  justify-items: center;
  position: absolute;
  z-index: 3;
  grid-gap: 2rem;
  text-align: center; }

.gameOverTitle {
  font-size: 1.5rem;
  margin: 2rem; }

.gameOverButton {
  border: 1px solid #e3ff92;
  padding: 1rem;
  cursor: pointer; }
  .gameOverButton:hover {
    color: #e3ff9280; }

.gameOverButtons {
  display: grid;
  -webkit-align-content: center;
          align-content: center;
  grid-gap: 2rem;
  grid-template-columns: 1fr 1fr; }

.container {
  color: #e3ff92;
  background-color: black;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  justify-items: center;
  -webkit-align-content: center;
          align-content: center;
  display: grid; }

.Menu {
  justify-items: center;
  -webkit-align-content: center;
          align-content: center;
  display: grid;
  grid-gap: 2rem;
  text-align: center;
  padding: 2rem;
  line-height: 1.5rem; }

.menuButton {
  border: 1px solid #e3ff92;
  padding: 1rem;
  cursor: pointer; }
  .menuButton:hover {
    color: #e3ff9280; }

.highScore {
  margin-top: 2rem; }

.title {
  line-height: 3rem; }

.gameModes {
  display: grid;
  -webkit-align-content: center;
          align-content: center;
  grid-gap: 2rem;
  grid-template-columns: 1fr 1fr; }

.gameModeTitle {
  font-size: 1.25rem;
  margin: 2rem; }

.gameModeButton {
  padding: 0.5rem;
  cursor: pointer; }
  .gameModeButton:hover {
    color: #e3ff9280; }

.Pause {
  color: #e3ff92;
  background-color: #00000080;
  display: grid;
  -webkit-align-content: center;
          align-content: center;
  justify-items: center;
  position: absolute;
  z-index: 3; }

.pausedTitle {
  font-size: 2rem;
  margin: 2rem; }

.continueButton {
  border: 1px solid #e3ff92;
  padding: 1rem;
  cursor: pointer; }
  .continueButton:hover {
    color: #e3ff9280; }

.Score {
  color: #e3ff92;
  display: grid;
  -webkit-align-items: center;
          align-items: center;
  padding: 1rem; }

.NavButtons {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  padding: 1rem 0; }

.navButton {
  border: 1px solid #e3ff92;
  padding: 1rem;
  cursor: pointer; }
  .navButton:hover {
    color: #e3ff9280; }

.App {
  background-color: black;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-content: center;
          align-content: center;
  grid-template-rows: 1fr 1fr 1fr;
  display: grid;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none; }

.installPrompt {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  width: 400px;
  position: fixed;
  box-shadow: 0 0 20px 2px rgba(0, 0, 0, 0.75);
  bottom: 0;
  left: 50%;
  z-index: 1;
  background-color: #ffffff;
  margin-left: -200px;
  font-family: 'Roboto'; }

.installDetailsContainer {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center;
  padding: 0.5rem; }

.installIcon {
  padding: .5rem;
  border-radius: 50%;
  width: 64px;
  height: 64px;
  -webkit-flex: 1 1;
          flex: 1 1; }

.installDetails {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-flex: 1 1;
          flex: 1 1;
  padding: 0.5rem; }

.installTitle {
  font-size: 1rem;
  color: #444; }

.installURL {
  font-size: 0.75rem;
  color: #777;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 250px; }

.installButtonContainer {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  padding: 0.5rem;
  background: #1159c5;
  font-size: 0.75rem;
  margin: 0.5rem;
  box-shadow: 2px 2px 2px 0px rgba(0, 0, 0, 0.2);
  cursor: pointer; }

.installButton {
  color: white; }

.installClose {
  padding: 0.5rem;
  cursor: pointer;
  color: #777; }

body {
    margin: 0;
    padding: 0;
    font-family: 'Press Start 2P', cursive;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: black;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
