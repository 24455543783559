.GameOver {
    color: #e3ff92;
    background-color: #00000080;
    display: grid;
    align-content: center;
    justify-items: center;
    position: absolute;
    z-index: 3;
    grid-gap: 2rem;
    text-align: center;
}

.gameOverTitle {
    font-size: 1.5rem;
    margin: 2rem;
}

.gameOverButton {
    border: 1px solid #e3ff92;
    padding: 1rem;
    cursor: pointer;
    &:hover {
        color: #e3ff9280;
    }
}

.gameOverButtons {
    display: grid;
    align-content: center;
    grid-gap: 2rem;
    grid-template-columns: 1fr 1fr;
}