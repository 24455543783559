.container {
    color: #e3ff92;
    background-color: black;
    user-select: none;
    justify-items: center;
    align-content: center;
    display: grid;
}

.Menu {
    justify-items: center;
    align-content: center;
    display: grid;
    grid-gap: 2rem;
    text-align: center;
    padding: 2rem;
    line-height: 1.5rem;
}

.menuButton {
    border: 1px solid #e3ff92;
    padding: 1rem;
    cursor: pointer;
    &:hover {
        color: #e3ff9280;
    }
}

.highScore {
    margin-top: 2rem;
}

.title {
    line-height: 3rem;
}

.gameModes {
    display: grid;
    align-content: center;
    grid-gap: 2rem;
    grid-template-columns: 1fr 1fr;
}

.gameModeTitle {
    font-size: 1.25rem;
    margin: 2rem;
}

.gameModeButton {
    padding: 0.5rem;
    cursor: pointer;
    &:hover {
        color: #e3ff9280;
    }
}